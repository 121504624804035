.fade-in {
    opacity: 0;
    transition: opacity 1.5s ease-in;
  }
  .fade-in.visible {
    opacity: 1;
    padding-top: 10px;
    transition: all 1s ease-out;
  }
  .fade2-in {
    opacity: 0;
    transition: opacity 0s ease-in;
  }
  .fade2-in.visible {
    opacity: 1;
    transition:opacity 1s ease-out;
  }  
  .fade-in_box1 {
    opacity: .5;
    transition: opacity 0s ease-in;
  }
  .fade-in_box1:hover {
    opacity: 1;
    transition: opacity 0s ease-in;
  }  
  .fade-in_box1.visible {
    opacity: 1;
    padding-top: 8px;
    transition:all.8s ease-out;
  }
  .spain_container
  {
    display: flex;
    justify-content: center;
    justify-items: center;
    width:100%;
   
  }
  .loader1 {
    border: 2px solid #323232;
    border-radius: 9999px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    margin-top: 20vh;
    display: block;
    height: 3em;
    position: relative;
    width: 3em;
      animation: spinner 2s linear infinite;
    }
    @keyframes spinner {
 
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  @media screen and (max-width: 1020px) 
  {
    .fade-in_box1 {
      opacity: 1;
    }
    .fade-in_box1.visible {
      
      padding-top: 10px;
     
    }
  }