body {
  margin: 0;
  overflow-y: hidden;
}
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2px;
}

.item {
  display: inline-block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.item img {
  max-width: 100%;
  height: auto;
  display: block;
}
.all_prod {
  width:80%;
}
.all_prod a{
  font-size: 34px;
  width:80%;
  z-index: 1000;
}
input, textarea{
  height: 34px;
  border:1px solid #616161;
  font-size: 16px;
  box-shadow: 0 0 5px rgb(180, 180, 180);
}
textarea{
  height:auto
}
.wrapper {
  height: 82vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
  background-color: #ffffff;
  z-index: 2000;
}

header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
}
.background {
  transform: translateZ(-10px) scale(2);
}

.foreground {
  transform: translateZ(-5px) scale(1.5);
  margin-top: -70px;
}

.background,
.foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  overflow-y: auto;
}
.sec_foreground
{
  transform: translateZ(-1px) scale(1.1);
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow-y: auto;
}

.title {
  font-size: 4rem;
  color: rgb(255, 255, 255);
  background-color:  rgb(87, 87, 87,.5);
  font-weight: bold;
  padding: 10px;
  /* text-shadow: 0 0 5px black; */
}
.parent_estimate {
  display: grid;
  grid-template-columns: .5fr repeat(2, 1fr) .5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 6px;
  grid-row-gap: 0px;

  }
  
  .dives1 { grid-area: 1 / 5 / 2 / 6; }
  .dives2 { grid-area: 1 / 5 / 2 / 6; }
  .dives3 { grid-area: 1 / 2 / 2 / 3; }
  .dives4 { grid-area: 1 / 3 / 2 / 4; }
.parent_james
{
    display: grid;
    grid-template-rows: repeat(2,1fr);
    grid-auto-columns: auto;
    gap: 0px;
    grid-auto-flow: row;
    align-items: left;
    align-content: left;
    position:relative;
   margin-top: 150px;
    z-index: 10000;
}
.div1,.div2,.div3
{
    color:#000000
}
.div1 { grid-area: 1 / 2 / 2 / 3; text-align: center}
.div2 { grid-area: 1 / 2 / 2 / 3;   text-align: center;}
.div3 {  grid-area: 1 / 3 / 2 / 4; text-align: center;transform: translateY(20px) ; align-content: right; }
.parent_james2
{
  display: grid;
  grid-template-rows: repeat(1,1fr);
  grid-auto-columns: auto;
  gap: 0px;
  grid-auto-flow: row;
  align-items: left;
  align-content: left;
  position:relative;
 margin-top: 50%;
  z-index: 10000;

}
.div22,.div32,.div42
{
    color:#000000;
     background-color:rgb(255, 255, 255,1);
    height: 300px;
    border-radius: 5px;
    font-size: 16px;
    min-width: 200px;
    max-width: 400px;
    padding: 10px;
    border: #b3b3b3 1px solid;
    box-shadow: 0 0 7px rgb(75, 75, 75);
    z-index: 10000;
}
.div12 { grid-area: 1 / 2 / 2 / 3; }
.div22 { grid-area: 1 / 2 / 2 / 4; }
.div32 { grid-area: 1 / 4 / 2 / 6; }
.div42 { grid-area: 1 / 6 / 2 / 8; }
.img_sample
{
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  padding: 6px;
  width:90%;

}
.img_sample:hover
{
  -webkit-transform: scale(1.08);
	transform: scale(1.08);
  padding: 6px;
  box-shadow: 0 0 7px black;
}
.div12:hover
{
  opacity: 1;
}

.banner_text
{
  width: 75%;
}
section {
  font-size:20px;
  padding: 2rem;
  background-color:#ffffff;
  color: rgb(0, 0, 0);
  margin-top: 80px;
}
.banner_button,.banner_button2{
  background-color:#3399CC ;
   border: 1px solid #ffffff;
   margin-top: 4px;
   height:50px;
   width:170px;
   color:#fff;
   font-size: 18px;
   padding-left: 25px;
   padding-right: 25px;
   padding-top: 8px;
   padding-bottom: 8px;  
   border-radius: 4px;
   box-shadow: 0 0 7px rgb(85, 85, 85); 
   cursor: pointer; 
 }
.banner_button2{ 
  height:30px;
  font-size: 16px;
  box-shadow: 0 0 0px rgb(85, 85, 85); 
  width:130px;
  padding-top: 0px;
  padding-bottom: 0px; 
 
}
.banner_button2:hover, .banner_button:hover
{
   background-color:#6f8e9e ;
   transition: all .5s;
}
 .services
 {
  background-color: #dbdbdb;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 12px rgb(146, 146, 146);
 }
 .newsletter
 {
  position: absolute;
  align-content: end;
  align-items: end;
  text-align: right;
  width: 100%;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  z-index: 120;
  color: #fff;
  background-color: rgb(102, 102, 102,.6);
 }
@media screen and (max-width: 1020px) 
  {
    .item {
      display: inline-block;
      width: 70%;
      padding: 10px;
      box-sizing: border-box;
    }
    .container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 2px;
    }
    .parent_estimate  {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 10px;
      align-items: center;
      align-content: center;
      text-align: center;
      }
      
      .dives3 { grid-area: 1 / 1 / 2 / 2; }
      .dives4 { grid-area: 2 / 1 / 3 / 2; padding: 20px; }
    .fade-in_box1 {
      opacity: 1;
    }
  .foreground,.buttons,.navbar-end,.services
  
  {
    display: none;
  }
  .parent_james
  {
    margin-top: 18%;
    padding-left: 0%;
  }
  .banner_button
  {
    margin-top: 10px;
  }
  .title {
    font-size: 1.8rem;
  }
  section {
    font-size: 1rem;
    padding: 1rem;
    margin-top: 10px;
  }
  .div3 { text-align: center; transition: all .7s;}
  .banner_text
  {
    width: 70%;
    transition: all .7s;
    margin-left: -10px;
    transform: translateY(10px) ;
  }

  .parent_james2 {
  
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 4px;
    grid-row-gap: 20px;
    place-items: center;
   align-items: center;
   align-content: center;
   margin-top: 73%;
    }
    .div22,.div32,.div42
  {
    background-color:rgb(255, 255, 255,1);
    padding: 5px;
    min-width: 290px;
    max-width: 290px;
    height: 200px;
    font-size: 14px;
    line-height: 15px;
  }
    .div22 { grid-area: 1 / 1 / 2 / 2; z-index: 100;}
    .div32 { grid-area: 2 / 1 / 3 / 2; }
    .div42 { grid-area: 3 / 1 / 4 / 2; }

    .img_sample
{
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  padding: 2px;
  width:70%;

}
}
@media screen and (max-height: 800px) 
  {
    
  .wrapper {
    height: auto;
  }
  .foreground,
  .services
 
  {
    display: none;
  }
}
/* @media screen and (min-width: 1500px) 
  {
    .parent_james2
      {
      margin-top: 40%;
      }
  } */