#textblock {
  /* background-color:rgb(255, 159, 159,.5); */
  height: 100vh;
  margin-top: 00px;
 width:73%;
 font-size: 24px;
}

#textblock-container {
  width: 50%;
  margin: 0 auto;

}

#textblock-title {
  color: #ffaf1b;
  font-size: 35px;
  font-weight: 600;
  font-family: "Helvetica Neue";
}

#textblock-content {
  color: #ffaf1b;
  font-size: 20px;
}

#textblock-footer {
  color: #ffaf1b;
  font-size: 15px;
  font-weight: 400;
  position: fixed;
  width: 100%;
  bottom: 0px;
  justify-content:center;
  align-items:center;
  text-align:center;
  margin-bottom: 20px;

}

#textblock-devsense {
  text-decoration: none;
  color: #ffaf1b;
  font-size: 15px;
  font-weight: 600;
}

.animation, .animation_layer {
	height: 900px;
}

.animation {
  display: block;
	position: relative;
	z-index: 10;
  height:20%
}

.animation_layer {
	background-position: bottom center;
	background-size: auto 1038px;
	background-repeat: repeat-x;
	width: 100%;
	position: absolute;
 
}
.animation_layer.parallax {
	position: fixed;
}

#artback {
  background-image: url(../images/Mountain1.jpg);
}

#mountain {
  background-image: url(../images/Trucks.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top:3%
}
#Words{
  margin-top: 200px;
}

@media screen and (max-width: 1020px) 
  {
    #textblock {
      margin-top: -40px;
     font-size: 18px;
    }
  }