@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300&display=swap');
.App {
  text-align: center;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
  



.containerlogo {
  display: flex;
  align-items: center;
  justify-content: center;

}

.containerlogo img {
  max-width: 100%;
  height: auto;
 
}




.slide-in.visible {
  max-height: 100px;
 opacity:1;
  transition: opacity 1.05s ease-in;
  /* background-color: aquamarine; */ 
    color:#ffffff ;
}
.slide-in {
  max-height: 0;
  opacity:0;
  transition:opacity .50s ease-out;
 /* background-color: rgb(255, 127, 223);  */
}


body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
  overflow-y: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.no-scroll {
  overflow-y: hidden;
}
.error{
  font-weight: bold;
  color: #FF0000;
  font-size: 18px;
}

div{
  margin-left:0 !important;;
  margin-right:0 !important;
}

.table-container {
  border: 0;
  width:70%
}
.testemonial
{
  font-size: 18px;
  width:100%;
  position:absolute;
   line-height: 22px;
  background-color: #cccccc;
  height:auto;
  color: #282c34;
  text-shadow: 12px 12px 14px rgba(9, 5, 5, 0);
}
.footer
{position:relative;
  color:#ffffff;
  padding-top: 20%;
  font-size: 16px;
  width:100%;
  margin-left: 20%;
  text-align: center;
 font-weight: bolder;
 line-height: 16px;
 align-items: center;
 height:auto
}

label
{
  font-family: 'Dosis', sans-serif;
  font-size: 18px;
}

.Appoint
{
  display:block
}
select{
  height:45px;
  font-size: 18px;
  padding:6px;
}
.warning
{
  font-size: 12px;
  color:#FF0000
}
.slide-container {
  overflow: hidden;
  transition:0.2s ease-in;
}
.visible {
  opacity: 1; /* Adjust this based on your design */

}
.hidden {
  opacity: .3;
  margin-left: 5px;
  transition:0.2s ease-in;
  /* box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3); /* Adjust values as needed */
}


/* LOADING ANIMATION --------------- */
.loader_contain
{
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: 200px;

}
.loader1 {

border: 2px solid #323232;
border-radius: 99px;
border-right-color: transparent;
border-top-color: transparent;
content: "";

display: block;
height: 3em;
position: relative;
width: 3em;
  animation: spinner 2s linear infinite;
}

.logo_main
{
  width:'656px';
  margin-top: 90px;
}

.logo_small
{ display: none;}


.bottom-component {
  position: fixed; /* or use 'absolute' depending on your layout */
  bottom: 0;
  left: 0;
  width: 100%;
  font-weight: 300;
  font-size:13px ;
  background-color: #833A24; /* Set your preferred background color */
  color:#ffffff;
  padding: 10px;
  text-align: center;
  z-index: 10000;
}
.rightsServed{
font-size: 11px;
}

@media screen and (max-width: 1020px) 
  {
   .navbar.is-primary .navbar-start > .navbar-item,
  .navbar.is-primary .navbar-start .navbar-link,
  .navbar.is-primary .navbar-end > .navbar-item,
  .navbar.is-primary .navbar-end .navbar-link {
    color: #000000;   /* ALERT:  color of the link  */
   line-height: 22px;
    font-size: 28px;
    width:500px
  }
  .wrapper {
    height: auto;
  }
  .buttons
  {
    display: none;
  }
  .navbar-dropdown/* .navbar-dropdown, .navbar-item  */
  {
    background-color: #61dafb;
  }
    #submenu
    {
      display: none;
    }
   
    .space {
      width: 10px; /* Adjust the width as needed */
    }

    .logo_main
    { display: none;}

    .logo_small
    { display: block;
      width:'14%'; 
      height:'14%';
      padding:'3px'
    }
    .containerlogo {
      display: flex;
      align-items: center;
      justify-content: center;
   
    }
    
    .containerlogo img {
      max-width: 100%;
      height: auto;
    }
    .navbar-brand
    {
      height:50px
    }
  }